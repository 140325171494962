<template>
  <div class="ocularOpticalTestingList">
    <div v-if="list && list.length > 0 && showContent">
      <div
        class="flexcolumnstart content"
        v-for="(item, index) in list"
        :key="index"
        @click="watchDetail(item)"
      >
        <p class="name">{{item.name || ''}}</p>
        <div class="flexrowbetween detail">
          <div>{{item.grade || ''}}{{item.className || ''}}</div>
          <div class="data">{{item.reportTime || ''}}</div>
        </div>
      </div>
    </div>
    <div class="nocontent" v-if="list && list.length == 0 && showContent">
      <img
        src="../../images/nocontent.png"
        style="
          width: 120px;
          height: 98px;
          margin-bottom: 20px;
          margin-top: 170px;
        "
      />
      <p style="margin-bottom:4px">暂未查询到结果</p>
      <p>如已完成检测，请稍后再次查询</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      name: '',
      birthDay: '',
      showContent:false,
    }
  },
  methods: {
    getDetail() {
      this.$axios
        .get(
          `/EHR/organization/screen/list/byNameAndCardNo?name=${this.name}&birthDay=${this.birthDay}`,
          { basurlType: 'commonurl' },
        )
        .then((res) => {
          this.list = res.data || []
          this.showContent = true
        })
        .catch((err) => {
          console.log(err)
          this.showContent = true
        })
    },
    watchDetail(item) {
      this.$router.push({
        path: '/temperatureAllowance/ocularOpticalTestingDetail',
        query:{
          id:item.id
        }
      })
    },
  },
  created() {
    this.name = this.$route.query.name
    this.birthDay = this.$route.query.birthDay
    this.getDetail()
  },
}
</script>
<style lang="scss">
@import '../../styles/common.scss';
@import '../../styles/flexcommon.scss';
.ocularOpticalTestingList {
  display: flex;
  flex-direction: column;
  background: #f4f5f7;
  height: 100vh;
  .content {
    background: #ffffff;
    margin-top: 20px;
  }
  .name {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
    padding: 22px 28px;
    border-bottom: 1px solid #eef1f5;
  }
  .detail {
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    padding: 26px 28px;
  }
  .data {
    font-size: 28px;
    color: #9c9fad;
  }
  .nocontent {
    text-align: center;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f5265;
  }
}
</style>
